<template>
  <th-modal
    :name="modalName"
    :title="$t('pages.export_history.files_dialog.title')"
    width="80vw"
    @close="$emit('close')"
  >
    <div class="">
      <th-datatable
        ref="table"
        resource="documents"
        :paging="false"
        :headers="headers"
        :show-tool-header="false"
        :do-route-search="false"
        :resource-query="resourceQuery"
        :prune-search-filters="pruneSearchFilters"
        :show-operations="false"
        height="50vh"
        @loading-error="handleLoadingError"
      />
    </div>
    <template #footer>
      <el-button type="primary" @click="$emit('close')">
        {{ $t('common.interactions.buttons.close') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
import ExportedFileLink from './exported-file-link.vue'

const EMPTY_CELL_TXT = '-'

export default {
  props: {
    modalName: {
      type: String,
      required: true
    },
    scheduleId: {
      type: String,
      default: undefined
    }
  },
  computed: {
    headers() {
      return [
        {
          field: 'createdAt',
          label: this.$t('pages.export_history.all.headers.created_at'),
          fallback: EMPTY_CELL_TXT,
          minWidth: 160,
          truncate: true,
          formatter: ({ createdAt }) => {
            return createdAt
              ? this.$date.formatDateTimeWithTimezone(createdAt)
              : undefined
          }
        },
        {
          field: 'documentNumber',
          label: this.$t('pages.export_history.all.headers.file'),
          fallback: EMPTY_CELL_TXT,
          minWidth: 180,
          truncate: true,
          customRowComponent: ExportedFileLink
        },
        {
          field: 'isSuccess',
          label: this.$t('common.headers.status.title'),
          fallback: EMPTY_CELL_TXT,
          minWidth: 150,
          truncate: true,
          formatter: ({ isSuccess }) => {
            return isSuccess
              ? this.$t('pages.export_history.status.completed')
              : this.$t('pages.export_history.status.error')
          }
        }
      ]
    },
    resourceQuery() {
      return {
        'schedule.id': this.scheduleId,
        deleted: false,
        size: 100
      }
    }
  },
  methods: {
    async refresh() {
      this.$refs.table.refresh()
    },
    pruneSearchFilters: () => {
      // Ensure we don't invlude filters from the parent route.
      return []
    },
    handleLoadingError(e) {
      this.$logException(e, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.export_history.files_dialog.title')
        })
      })
    }
  }
}
</script>
