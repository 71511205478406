<template>
  <el-button
    class="p-0"
    text
    :disabled="false"
    @click.stop="handleClick(scope.row)"
  >
    {{ scope.row.documentNumber }}
  </el-button>
</template>
<script>
import th from '@tillhub/javascript-sdk'
import { useExportsStore } from '@/store/exports'
import { DocumentTypeTranslationKeys } from '../helpers'
import { saveAs } from 'file-saver'

export default {
  props: {
    scope: {
      type: Object,
      default: null
    }
  },
  methods: {
    async handleClick(row) {
      try {
        const { id, documentType } = row
        const {
          data,
          contentType,
          url,
          filename,
          correlationId
        } = await th.documents().download(id)
        if (correlationId) {
          // Document needs to be regenerated.
          useExportsStore().setNewExport({
            exportId: correlationId,
            payload: {
              originKey: DocumentTypeTranslationKeys[documentType],
              date: new Date(),
              action: {
                entity: documentType
              }
            }
          })
        } else if (url) {
          // We have a direct url to the document. Download the file.
          const link = document.createElement('a')
          link.href = url
          link.click()
        } else if (data && contentType && filename) {
          // We have the document data. Download the file.
          const link = document.createElement('a')
          link.href = `data:${contentType};base64,${data}`
          link.download = filename
          link.click()
        } else {
          throw new Error('Nothing to download')
        }
      } catch (error) {
        this.$logException(error, {
          message: this.$t('notifications.file.download.fail')
        })
      }
    }
  }
}
</script>
<style></style>
