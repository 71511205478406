<template>
  <th-page-wrapper>
    <files-dialog
      v-if="!!filesDialogScheduleId"
      modal-name="files-dialog"
      :schedule-id="filesDialogScheduleId"
      @close="closeFilesDialog"
    />
    <th-datatable
      ref="table"
      resource="documentExports"
      show-filter
      :route-base="routeBase"
      do-route-filters
      :headers="headers"
      :resource-limit="1000"
      :resource-query="resourceQuery"
      :search-filters="filtersList"
      :show-operations="true"
      operations-width="150"
      :labels="{ table: { headers: { operations: '' } } }"
      :transform-fetched-data="transformFetchedData"
      @loading-error="handleLoadingError"
    >
      <template #operations="scope">
        <div class="flex justify-end">
          <a
            v-if="scope.row.schedule"
            target="_blank"
            class="flex px-3 el-button--text-icon cursor-pointer"
            download
            @click.stop="handleViewFilesClick(scope.row)"
          >
            <el-tooltip
              placement="top"
              effect="dark"
              :content="$t('pages.export_history.all.operations.view_files')"
            >
              <el-icon class="text-lg">
                <Document />
              </el-icon>
            </el-tooltip>
          </a>
          <a
            v-if="scope.row.schedule?.active === true"
            class="flex px-3 el-button--text-icon cursor-pointer"
            @click.stop.prevent="handleToggleRecurringClick(scope.row)"
          >
            <el-tooltip
              placement="top"
              effect="dark"
              :content="$t('pages.export_history.all.buttons.stop_recurrence')"
            >
              <el-icon class="text-lg">
                <svgicon
                  :src="require(`@/assets/icons/th-icon-pause.svg`)"
                  class="svg-icon"
                />
              </el-icon>
            </el-tooltip>
          </a>
          <a
            v-if="scope.row.schedule?.active === false"
            class="flex px-3 el-button--text-icon cursor-pointer"
            @click.stop.prevent="handleToggleRecurringClick(scope.row)"
          >
            <el-tooltip
              placement="top"
              effect="dark"
              :content="
                $t('pages.export_history.all.buttons.resume_recurrence')
              "
            >
              <el-icon class="text-lg">
                <svgicon
                  :src="require(`@/assets/icons/th-icon-play.svg`)"
                  class="svg-icon"
                />
              </el-icon>
            </el-tooltip>
          </a>
        </div>
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import ExportedFileLink from './components/exported-file-link.vue'
import FilesDialog from './components/files-dialog.vue'
import th from '@tillhub/javascript-sdk'
import { DocumentTypeTranslationKeys } from './helpers'

const EMPTY_CELL_TXT = '-'

export default {
  components: { FilesDialog },
  data() {
    return {
      filesDialogScheduleId: null
    }
  },
  computed: {
    routeBase() {
      return this.$router.resolve({ name: 'export-history' }).path
    },
    metaInfo() {
      return {
        title: this.$t('nav.main.items.utilities.export_history')
      }
    },
    translations() {
      return {
        status: {
          pending: this.$t('pages.export_history.status.pending'),
          completed: this.$t('pages.export_history.status.completed'),
          error: this.$t('pages.export_history.status.error')
        },
        scheduleStatus: {
          active: this.$t('pages.export_history.schedule_status.active'),
          inactive: this.$t('pages.export_history.schedule_status.inactive'),
          none: this.$t('pages.export_history.schedule_status.none')
        },
        documentType: Object.fromEntries(
          Object.entries(DocumentTypeTranslationKeys).map(([key, value]) => [
            key,
            this.$t(value)
          ])
        )
      }
    },
    headers() {
      return [
        {
          field: 'createdAt',
          label: this.$t('pages.export_history.all.headers.created_at'),
          fallback: EMPTY_CELL_TXT,
          width: 140,
          truncate: true,
          formatter: ({ createdAt }) => {
            return createdAt
              ? this.$date.formatDateTimeWithTimezone(createdAt)
              : undefined
          }
        },
        {
          field: 'documentType',
          label: this.$t('pages.export_history.all.headers.exported_data'),
          fallback: EMPTY_CELL_TXT,
          minWidth: 150,
          truncate: true,
          formatter: (row) => this.translations.documentType[row.documentType]
        },
        {
          field: 'exported_by',
          label: this.$t('pages.export_history.all.headers.exported_by'),
          fallback: EMPTY_CELL_TXT,
          minWidth: 140,
          truncate: true,
          formatter: (row) =>
            safeGet(row, 'user')?.name ||
            safeGet(row, 'user')?.email ||
            EMPTY_CELL_TXT
        },
        {
          field: 'status',
          label: this.$t('common.headers.status.title'),
          fallback: EMPTY_CELL_TXT,
          width: 130,
          truncate: true,
          formatter: (row) => this.translations.status[row.status]
        },
        {
          field: 'documentNumber',
          label: this.$t('pages.export_history.all.headers.latest_export'),
          fallback: EMPTY_CELL_TXT,
          minWidth: 200,
          truncate: true,
          customRowComponent: ExportedFileLink
        },
        {
          field: 'recurring',
          label: this.$t('pages.export_history.all.header.recurring'),
          fallback: EMPTY_CELL_TXT,
          width: 140,
          truncate: true,
          formatter: (row) =>
            this.translations.scheduleStatus[this.scheduleStatus(row)]
        }
      ]
    },
    filtersList() {
      return [
        {
          name: 'documentType',
          type: 'multiselect',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.export_history.all.headers.exported_data'),
          options: this.getOptions('documentType'),
          filterable: true
        },
        {
          name: 'user.name',
          type: 'input',
          label: this.$t('pages.export_history.all.headers.exported_by')
        },
        {
          name: 'exportStatus',
          type: 'select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('common.headers.status.title'),
          filterable: true,
          options: this.getOptions('status')
        },
        {
          name: 'scheduleStatus',
          type: 'select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.export_history.all.header.recurring'),
          filterable: true,
          options: this.getOptions('scheduleStatus')
        },
        {
          name: 'date',
          type: 'daterange',
          prop: ['start', 'end'],
          preventDefaultDates: true,
          label: this.$t('pages.export_history.all.headers.created_at'),
          formatValue: (value) => this.$date.formatDateRange(value),
          modifyFilter: ({ start, end }) => {
            // if end date is in the future, send now as the end date
            const now = new Date()
            const endDate = now < new Date(end) ? now.toISOString() : end
            return { start, end: endDate }
          },
          noFutureDates: true
        }
      ]
    },
    minPossibleSelectedDate() {
      // Date object represents the date 90 days ago starting from midnight
      const date = new Date()
      date.setDate(date.getDate() - 90)
      date.setHours(0, 0, 0, 0)
      return date
    },
    resourceQuery() {
      return {}
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh()
    },
    scheduleStatus(row) {
      if (row.schedule) {
        return row.schedule.active ? 'active' : 'inactive'
      }
      return 'none'
    },
    transformFetchedData(data) {
      return data
    },
    handleLoadingError(e) {
      this.$logException(e, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('nav.main.items.utilities.export_history')
        })
      })
    },
    async handleToggleRecurringClick(row) {
      try {
        const { id: scheduleId } = row.schedule
        await th.scheduledExports().toggle(scheduleId)
        this.refresh()
      } catch (error) {
        this.$logException(error, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t(
              'pages.export_history.resource.recurring_export.singular'
            )
          })
        })
      }
    },
    handleViewFilesClick(row) {
      if (row.schedule?.id) {
        this.filesDialogScheduleId = row.schedule.id
        this.$nextTick(() => {
          this.$thModal.show('files-dialog')
        })
      }
    },
    closeFilesDialog() {
      this.filesDialogScheduleId = null
    },
    getOptions(type) {
      return Object.entries(this.translations[type]).map(([value, label]) => ({
        label,
        value
      }))
    }
  }
}
</script>

<style scoped></style>
